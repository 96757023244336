/*
 * colors.less
 */
.tableContainer {
  width: 100%;
  overflow-x: auto;
}
.tableContainer .table {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}
.tableContainer .table .tableHeader {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 18px 24px;
  background-color: #f8faff;
  color: #7982ad;
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  border-bottom: 1px solid #dae0f4;
}
.tableContainer .table .tableHeader .name {
  width: 300px;
}
.tableContainer .table .tableHeader .headerStage {
  flex: 2 1;
}
.tableContainer .table .tableHeader .headerStage:nth-last-child(2),
.tableContainer .table .tableHeader .headerStage:last-of-type {
  flex: 0;
  min-width: 100px;
}
.tableContainer .table .tableHeader .headerStage.archived {
  text-align: center;
}
