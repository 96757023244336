@import 'src/less/colors';

.tableRow {
  display: flex;
  min-height: 64px;
  padding: 0 24px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid @SecondaryBlue20;
  }
}

.name {
  width: 300px;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: @SecondaryBlue;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.nameLink:hover {
    text-decoration: underline;
  }
}

.stage {
  flex: 1;
  display: flex;

  &.wider {
    flex: 2;
  }

  &:last-of-type,
  &:nth-last-of-type(2) {
    flex: 0;
    min-width: 100px;
  }
}

.stageCount {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  width: 60px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: @PrimaryCobalt80;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.funnel {
  flex: 1;
  text-align: center;
}
