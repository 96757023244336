@import 'src/less/colors';

.tableContainer {
  width: 100%;
  overflow-x: auto;
  .table {
    // overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-width: fit-content;

    .tableHeader {
      display: flex;
      align-items: center;
      height: 54px;
      padding: 18px 24px;
      background-color: @SecondarySkyBlue05;
      color: @PrimaryCobalt60;
      font-size: 14px;
      font-family: Graphik;
      font-weight: 500;
      border-bottom: 1px solid @SecondaryBlue20;
      // min-width: fit-content;

      .name {
        width: 300px;
      }

      .headerStage {
        //min-width: 160px;
        flex: 2 1;

        &:nth-last-child(2),
        &:last-of-type {
          // padding-right: 12px;
          // min-width: fit-content;
          flex: 0;
          min-width: 100px;
        }

        &.archived {
          text-align: center;
        }
      }
    }
  }
}
