/*
 * colors.less
 */
.tableRow {
  display: flex;
  min-height: 64px;
  padding: 0 24px;
  align-items: center;
}
.tableRow:not(:last-child) {
  border-bottom: 1px solid #dae0f4;
}
.name {
  width: 300px;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: #4864c9;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.name.nameLink:hover {
  text-decoration: underline;
}
.stage {
  flex: 1;
  display: flex;
}
.stage.wider {
  flex: 2;
}
.stage:last-of-type,
.stage:nth-last-of-type(2) {
  flex: 0;
  min-width: 100px;
}
.stageCount {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  width: 60px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Graphik;
  color: #4c5892;
}
.stageCount:not(:disabled) {
  cursor: pointer;
}
.stageCount:not(:disabled):hover {
  text-decoration: underline;
}
.funnel {
  flex: 1;
  text-align: center;
}
